"use strict";
  
// Sélectionner tous les éléments du projet
const projectElements = document.querySelectorAll('.projet__el');

const closeButtons = document.querySelectorAll('.closePopup');

projectElements.forEach(element => {
    element.addEventListener('click', () => {
        const popupClass = element.getAttribute('data-popup');
        const popup = document.querySelector(`.${popupClass}`);
        if (popup) {
            popup.classList.toggle('popup-modal--visible');
        }
    });
});

closeButtons.forEach(button => {
    button.addEventListener('click', () => {
        const popup = button.closest('.popup-modal');
        if (popup) {
            popup.classList.toggle('popup-modal--visible');
        }
    });
});

  

// année
var annee = new Date().getFullYear();
document.querySelector(".annee").innerHTML = annee;



// anime écriture

document.addEventListener("DOMContentLoaded", function() {
  const textElement = document.querySelector('.title--anim');
  const text = textElement.textContent;
  textElement.textContent = '';
  let index = 0;

  function type() {
      if (index < text.length) {
          textElement.textContent += text.charAt(index);
          index++;
          setTimeout(type, 100); 
      }
  }

  type();
});




// scroll annime
const competenceObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show-competence');
      }
    });
  },
  {
    threshold: 0,
    rootMargin: '0px'
  }
);

const flecheObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show-fleche');
      }
    });
  },
  {
    threshold: 0,
    rootMargin: `-${window.innerHeight / 3}px 0px 0px 0px`
  }
);

const titleObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
      }
    });
  },
  {
    threshold: 0.1
  }
);

// Fonction d'initialisation
function initializeAnimations() {
  // Observer les éléments de compétence
  const competenceElements = document.querySelectorAll('.competence-animation');
  competenceElements.forEach(element => {
    competenceObserver.observe(element);
  });

  // Observer les éléments flèche
  const flecheElements = document.querySelectorAll('.flecheTransl');
  flecheElements.forEach(element => {
    flecheObserver.observe(element);
  });

  // Observer le titre d'engagement
  const titleElement = document.querySelector('.title--engagement');
  if (titleElement) {
    titleObserver.observe(titleElement);
  }
}

// Initialisation au chargement de la page
document.addEventListener('DOMContentLoaded', initializeAnimations);



// animation du titre projet avec gsap

gsap.fromTo('.title--projets',
  { x: -100 }, // Position de départ
  {
    scrollTrigger: {
      trigger: '.title--projets',
      start: 'top 80%',
      end: 'bottom 20%',
      scrub: 1,
      // markers: true,
    },
    x: 100, // Position finale
    ease: 'power1.inOut'
  }
);


